<template>
  <div :id="promotionalBannerID" class="home-promotional-banner">
    <a class="home-promotional-banner__link" :href="promotionalBannerUrl" :target="isItExternalLink">
      <template v-if="innerWidth < 600">
        <img
          class="home-promotional-banner__img home-promotional-banner__img--mobile skeleton"
          :src="currentBanner.image_mobile.url"
          :alt="promotionalBannerName"
          height="100%"
          width="100%"
        />
      </template>
      <template v-else-if="innerWidth >= 600 && innerWidth < 1024">
        <img
          class="home-promotional-banner__img home-promotional-banner__img--tablet skeleton"
          :src="currentBanner.image_tablet.url"
          :alt="promotionalBannerName"
          height="100%"
          width="100%"
        />
      </template>
      <template v-else>
        <img
          class="home-promotional-banner__img home-promotional-banner__img--desktop skeleton"
          :src="currentBanner.image_desktop.url"
          :alt="promotionalBannerName"
          height="100%"
          width="100%"
        />
      </template>
    </a>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import debounce from 'lodash/debounce';

  export default {

    data(){
      return {
        innerWidth: 300
      }
    },
    props: ['position'],
    computed: {
      ...mapState({
        topPromotionalBanner: state => state.home.topPromotionalBanner,
        middlePromotionalBanner: state =>  state.home.middlePromotionalBanner
      }),
      isItExternalLink() {
        return this.currentBanner.open_in_new_tab ? '_blank':'_self';
      },
      promotionalBannerUrl() {
        return this.currentBanner.url;
      },
      promotionalBannerName() {
        return this.currentBanner.name;
      },
      promotionalBannerID () {
        return `promotional-banner--` + this.currentBanner.id
      },
      currentBanner(){
        return this.position == 'top' ? this.topPromotionalBanner : this.middlePromotionalBanner;
      }
    },
    mounted(){
      this.checkMobile();
      window.addEventListener('resize', debounce(this.checkMobile, 200));
    },
    methods:{
      checkMobile() {
        this.innerWidth = window.innerWidth;
      }
    }
  }
</script>

<style>
  .home-promotional-banner__link {
    display: block;
    min-height: 225px;
    @media(--viewport-tablet){
      min-height: 250px;
    }
    @media(--viewport-desktop){
      min-height: 300px;
    }
    @media(--viewport-large-desktop){
      min-height: 456px;
    }
  }

  .home-promotional-banner__img {
    display: none;
    width: 100%;
  }

  .home-promotional-banner__img--desktop {
    @media(--viewport-desktop) {
      display: block;
    }
  }

  .home-promotional-banner__img--tablet {
    @media(--viewport-small-tablet) {
      display: block;
    }

    @media(--viewport-desktop) {
      display: none;
    }
  }

  .home-promotional-banner__img--mobile {
    display: block;

    @media(--viewport-small-tablet) {
      display: none;
    }
  }

  /* .skeleton {
    background-color: #e2e5e7;
    background-image: linear-gradient(90deg, rgba(255, 255,255, 0),rgba(255, 255,255, 0.5), rgba(255, 255,255, 0));
    background-size: 40px 100%;
    background-repeat: no-repeat;
    background-position: left -40px top 0;
    animation: shine 1.5s ease infinite;
    @media(--viewport-tablet){
      min-height: 250px;
    }
    @media(--viewport-desktop){
      min-height: 300px;
    }
    @media(--viewport-large-desktop){
      min-height: 456px;
    }
  }

  @keyframes shine {
    from{
      background-position: left 0 top 0;
    }
    to {
      background-position: right -40px top 0;
    }
  } */
</style>
