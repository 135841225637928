<template>
  <div class="home">

    <div class="home__section home__section--main">
      <HomePromotionalBanners position="top" />
    </div>

    <div
      class="home__section home__section--products"
      v-if="popularProducts.length > 0"
      id="popularProducts"
      ref="popularProducts"
    >
      <ProductCarousel :title="$t('top-selling-product')" :products="popularProducts" id="popular"/>
    </div>


    <div v-for="category in topCategories" class="home__section home__section--carousel" :key="category">
      <div class="home__section home__section--products" v-if="productLists[category].items.length > 0" :id="category" :ref="category">
        <ProductCarousel :title="productLists[category].title" :products="productLists[category].items" :id="category"/>
      </div>
    </div>

    <div
      v-if="shouldShowSection"
      class="home__section home__section--promotional_banners"
    >
      <HomePromotionalBanners position="middle"/>
    </div>


    <div class="home__section home__section--carousel" v-for="category in belowMiddleCategories" :key="category">
      <div class="home__section home__section--products" v-if="productLists[category].items.length > 0" :id="category" :ref="category">
        <ProductCarousel :title="productLists[category].title" :products="productLists[category].items" :id="category"/>
      </div>
    </div>

    <div class="home__section home__section--discover" v-if="shouldShowSection">
      <HomeDiscover/>
    </div>

    <div class="home__section home__section--benefits" id="homeBenefits" v-if="showHomeBenefits" ref="homeBenefits">
      <HomeBenefits/>
    </div>

    <div class="home__section home__section--customer" id="reviewSlider" v-if="showReviews" ref="reviewSlider">
      <HomeCustomersSlider title="Hear what our customers are saying" :reviewsStatus="reviewsStatus"/>
    </div>

    <div class="home__section home__section--seller" v-if="shouldShowSection">
      <HomeBecome/>
    </div>

    <div class="home__section home__section--partners" v-if="shouldShowSection">
      <HomePartners/>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapState, mapActions, mapMutations } from 'vuex';
  import SeoMetaService from '@/services/seo-meta-service';
  import HomePromotionalBanners from '@/components/home/HomePromotionalBanners.vue';
  import cacheControl from '../helpers/cache-control';
  import { POPUP_NAMES } from '@/utils/popup-names';

  const TOP_CATEGORY_BRIDGE = 'bridge';
  const TOP_CATEGORY_DECK = 'deck';
  const TOP_CATEGORY_ENGINEERING = 'engineering';
  const TOP_CATEGORY_GALLEY = 'galley';
  const TOP_CATEGORY_INTERIOR = 'interior';

  export default {
    layout: 'BaseLayout',
    components: {
    HomeDiscover:() => import('@/components/home/HomeDiscover'),
    HomeBenefits:() => import('@/components/home/HomeBenefits'),
    ProductCarousel:() => import('@/components/product/ProductCarousel'),
    ProductPreview:() => import('@/components/product/previews/ProductPreview'),
    HomeBecome:() => import('@/components/home/HomeBecome'),
    HomePartners:() => import('@/components/home/HomePartners'),
    HomeCustomersSlider:() => import('@/components/home/customers/HomeCustomersSlider'),
    HomePromotionalBanners
    },
    data() {
      return {
        shouldShowSection: false,
        elementsArr: [],
        once:false,
        showReviews:false,
        showHomeBenefits: false
      };
    },
    computed: {
      ...mapGetters({
        productsBySlug: 'home/productsBySlug',
      }),
      ...mapState({
        popularProducts: state => state.home.popularProducts,
        currentUrl: state => state.currentUrl,
        portData:  state => state.port.portData,
        reviewsStatus: state => state.home.reviewsStatus,
        isFetching: state => state.home.isFetching
      }),
      topCategories() {
        return [TOP_CATEGORY_BRIDGE];
      },
      belowMiddleCategories() {
        return [TOP_CATEGORY_DECK, TOP_CATEGORY_ENGINEERING, TOP_CATEGORY_GALLEY, TOP_CATEGORY_INTERIOR];
      },
      productLists() {
        return {
          [TOP_CATEGORY_BRIDGE]: {
            title: this.$t('category-shop-bridge'),
            items: this.productsBySlug.bridge,
          },
          [TOP_CATEGORY_DECK]: {
            title: this.$t('category-shop-deck'),
            items: this.productsBySlug.deck,
          },
          [TOP_CATEGORY_ENGINEERING]: {
            title: this.$t('category-shop-engineering'),
            items: this.productsBySlug.engineering,
          },
          [TOP_CATEGORY_GALLEY]: {
            title: this.$t('category-shop-galley'),
            items: this.productsBySlug.galley,
          },
          [TOP_CATEGORY_INTERIOR]: {
            title: this.$t('category-shop-interior'),
            items: this.productsBySlug.interior,
          }
        };
      }
    },
    mounted(){
      if(this.$route?.query?.login){
        this.setComponent(POPUP_NAMES.LOGIN_POPUP)
      }
      window.addEventListener("scroll", this.handleScroll,{passive:true});
      this.elmentObserveArray();
      this.showTrustpilotReview();
    },
    updated(){
      this.elmentObserveArray();
    },
    beforeDestroy() {
      window.removeEventListener("scroll", this.handleScroll,{passive:true});
    },
    methods: {
      ...mapMutations({
        setComponent: 'modal/setComponent'
      }),
      ...mapActions({
        fetchHomePageProducts: 'home/fetchHomePageProducts',
        showTrustpilotReview: 'home/showTrustpilotReview'
      }),
      handleScroll() {
        const el = document.querySelector(".home__section--products");
        if (el) {
          const rect = el.getBoundingClientRect();
          if (rect.top <= window.innerHeight && !this.shouldShowSection) {
            this.shouldShowSection = true;
          }
        }
      },
      intersectionCallback(entries, observer) {
        if(!entries?.length) return;
        for (const entry of entries) {
          if (entry.isIntersecting) {
            if(entry.target.id == "interior" && !this.showHomeBenefits){
              this.showHomeBenefits = true;
              return;
            }
            if(entry.target.id == "homeBenefits"){
              this.showReviews = true;
              return;
            }
            const categories = [TOP_CATEGORY_BRIDGE, TOP_CATEGORY_DECK, TOP_CATEGORY_ENGINEERING, TOP_CATEGORY_GALLEY, TOP_CATEGORY_INTERIOR];
            const currEl = this.elementsArr.find(el => entry.target.id === el.id);
            const index = this.elementsArr.indexOf(currEl);
            const elementToFetch = categories[index];
            if(!elementToFetch) return;
            if (this.productsBySlug[elementToFetch]?.length || this.isFetching === elementToFetch) continue;
            this.fetchHomePageProducts({ slug: elementToFetch })
          }
        }
      },

      elmentObserveArray(){
        const options = {
          root:null,
          threshold:0.7,
          rootMargin:'0px'
        };
        const observer = new IntersectionObserver(this.intersectionCallback, options);
        const popularProducts = this.$refs?.popularProducts;
        const bridge = this.$refs?.bridge;
        const deck = this.$refs?.deck;
        const engineering = this.$refs?.engineering;
        const galley = this.$refs?.galley;
        const interior = this.$refs?.interior;
        const homeBenefits = this.$refs.homeBenefits;
        const elementToObserve = [popularProducts,bridge, deck, engineering, galley, interior, homeBenefits];
        let elementsArr = [];
        elementToObserve.forEach((el) => {
          if(el) Array.isArray(el) ? elementsArr.push(el[0]) : elementsArr.push(el);
        });
        let set = [...new Set(elementsArr)];
        this.elementsArr = [...set];
        elementsArr.forEach((el) => {
          observer.observe(el);
        });
      }
    },
    async fetch({ store, params, route }) {
      await store.dispatch('shared/fetchCategories');
      await store.dispatch('home/fetchHomePageData', { params });
      await store.dispatch('home/fetchPromotionalBannersBySlug', {slug: 'home'});
      if (route.params.port_slug) {
        await store.dispatch('port/loadPortData', { slug: route.params.port_slug });
      }
    },
    head({$store}) {
      const canonicalUrl = process.env.FULL_DOMAIN
      let seoData = SeoMetaService.forHomePage(this.currentUrl, this.portData, canonicalUrl);
      const desktopBannerUrl = $store.state.home.topPromotionalBanner.image_desktop.thumb.url;
      const tabletBannerUrl = $store.state.home.topPromotionalBanner.image_tablet.thumb.url;
      const mobileBannerUrl = $store.state.home.topPromotionalBanner.image_mobile.thumb.url;
      const desktopBannerLink = {
        rel: "preload",
        as: "image",
        href: desktopBannerUrl
      }
      const tabletBannerLink = {
        rel: "preload",
        as: "image",
        href: tabletBannerUrl
      }
      const mobileBannerLink = {
        rel: "preload",
        as: "image",
        href: mobileBannerUrl
      }
      seoData.link = [...seoData.link, desktopBannerLink, tabletBannerLink, mobileBannerLink];

      return seoData;
    },
    middleware: cacheControl({
      'max-age': 60,
      'stale-when-revalidate': 5
    })
  }
</script>

<style>
  .home__section--main {
    margin-bottom: 14px;

  }

  .home__section--customer {
    margin-bottom: 30px;
    @media (--viewport-tablet) {
      margin-bottom: 80px;
    }
  }

  .home__section--promotional_banners {
    margin-top: 12px;
    margin-bottom: 22px;


  }

  .home__section--products {
    margin-bottom: -40px;

    @media (--viewport-tablet) {
      margin-bottom: -86px;
    }
  }

  @keyframes pulse {
    0% {
      background-image: linear-gradient(to right, #f2f2f2 0%, #e6e6e6 20%, #f2f2f2 40%);
    }
    100% {
      background-image: linear-gradient(to right, #f2f2f2 0%, #e6e6e6 60%, #f2f2f2 100%);
    }
  }

  @keyframes shine {
    from{
      background-position: left 0 top 0;
    }
    to {
      background-position: right -40px top 0;
    }
  }


</style>
