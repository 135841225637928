class SeoMetaServiceClass {

  forProduct(product, url, portData, canonicalUrl, isItPromoted, minPrice) {
    const portPrefix = portData ? (portData.name + ' - ') : '';
    const title = portPrefix + (product?.meta_data?.meta_title || (product.name + ' | Yachtneeds'));
    const description = product?.meta_data?.meta_description || this._cutHtml(product.description);
    const keywords = portData ? '' : product?.meta_data?.meta_keyword;
    const firstImage = product.gallery_images[0];
    let meta = this._getBaseMetaData(title, description, keywords, url, 'product');
    meta = meta.concat([
      { hid: 'ogPriceAmount', property: 'og:price:amount', content: minPrice ? minPrice : product.min_price },
      { hid: 'ogPriceCurrency', property: 'og:price:currency', content: 'USD' }
    ]);
    if (firstImage) {
      meta = meta.concat(this._getImageData(firstImage.original_image));
    }
    if (isItPromoted) {
      meta = meta.concat([ 
        { hid: 'canonical', rel: 'canonical', content: canonicalUrl }
      ]);
    }
    return this._formSeoData(title, meta, portData, canonicalUrl);
  }

  forCategory(category, url, portData, canonicalUrl) {
    let title;
    let description;
    let keywords;
    if (portData) {
      title = [portData.name, category?.meta_data?.meta_title || category.name].join(' - ');
      description = category?.meta_data?.meta_description || this._cutHtml(category.body_html);
      keywords = '';
    } else {
      title = category?.meta_data?.meta_title;
      description = category?.meta_data?.meta_description;
      keywords = category?.meta_data?.meta_keyword;
    }
    let meta = this._getBaseMetaData(title, description, keywords, url, 'product');
    if (category.picture) {
      meta = meta.concat(this._getImageData(category.picture));
    }
    if (category.is_empty_category) {
      meta.push({ hid: 'robots', name: 'robots', content: 'noindex' });
    }
    return this._formSeoData(title, meta, portData, canonicalUrl);
  }

  forPage(page, url) {
    const title = page.name + ' | Yachtneeds Marketplace';
    const description = '';
    let meta = this._getBaseMetaData(title, description, '', url, 'product');
    meta = meta.concat([
      { hid: 'fbAdmins', property: 'fb:admins', content: 'yachtneeds' }
    ]);
    return {
      title: title,
      meta: meta
    };
  }

  forHomePage(url, portData, canonicalUrl) {
    const title = 'YACHTNEEDS marketplace: buy superyacht specific products';
    let meta = this._getBaseMetaData(title, '', '', url, 'product');
    meta = meta.concat([
      { hid: 'fbAdmins', property: 'fb:admins', content: 'yachtneeds' },
      {
        hid: 'description',
        name: 'description',
        content: 'Instantly shop all the superyacht supplies you need, from Bridge, Deck, Engineering, Interior,' +
          ' Galley, and have your orders delivered straight to your yacht.'
      },
      { hid: 'ogImage', property: 'og:image', content: `${process.env.FULL_DOMAIN}/static/seo/home-image` }
    ]);
    return this._formSeoData(title, meta, portData, canonicalUrl);
  }

  forPort(port, url, seoData) {
    const title = seoData.meta_data.meta_title || port.name;
    const description = seoData.meta_data.meta_description || port.description;
    const keywords = seoData.meta_data.meta_keyword;
    let meta = this._getBaseMetaData(title, description, keywords, url, 'product');
    if (port.logo) {
      meta = meta.concat(this._getImageData(port.logo));
    }
    return {
      title: title,
      meta: meta
    };
  }

  _getBaseMetaData(title, description, keywords, url, type) {
    return [
      { hid: 'name', name: 'name', content: title },
      { hid: 'description', name: 'description', content: description },
      { hid: 'keywords', name: 'keywords', content: keywords },
      { hid: 'twitterCard', name: 'twitter:card', content: type },
      { hid: 'twitterSite', name: 'twitter:site', content: '@yachtneeds' },
      { hid: 'twitterTitle', name: 'twitter:title', content: title },
      { hid: 'twitterDescription', name: 'twitter:description', content: description },
      { hid: 'twitterCreator', name: 'twitter:creator', content: '@YachtNeedsapp' },
      { hid: 'ogTitle', property: 'og:title', content: title },
      { hid: 'ogType', property: 'og:type', content: type },
      { hid: 'ogUrl', property: 'og:url', content: url },
      { hid: 'ogDescription', property: 'og:description', content: description },
      { hid: 'ogSiteName', property: 'og:site_name', content: 'Yachtneeds' }
    ].filter((metaItem) => metaItem.content);
  }

  _getImageData(image) {
    if (image.url) {
      return [
        { hid: 'image', name: 'image', content: image.url },
        { hid: 'twitterImage', name: 'twitter:image', content: image.url },
        { hid: 'ogImage', property: 'og:image', content: image.url }
      ];
    } else {
      return [];
    }
  }

  _cutHtml(html, cutTo = 160) {
    if (html) {
      let cutString = html.replace(/(<([^>]+)>)/ig, '').trim().substr(0, cutTo);
      if (cutString.length === cutTo) {
        cutString = cutString.substr(0, Math.min(cutString.length, cutString.lastIndexOf(' ')));
      }
      return cutString;
    } else {
      return html;
    }
  }

  _formSeoData(title, meta, portData, canonicalUrl) {
    let seoData = {
      title: title,
      meta: meta
    };
    // if (portData) {
      seoData.link = [
        {
          rel: 'canonical',
          href: canonicalUrl
        }
      ];
    // }
    return seoData;
  }

}

const SeoMetaService = new SeoMetaServiceClass();

module.exports = SeoMetaService;
